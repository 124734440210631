<mat-toolbar class="header">
  <mat-toolbar-row>
    <img class="logo" src="../../../../assets/images/properly_agent_logo.svg" alt="properly-logo" />
    <div class="links">
      <a class="link" href="#" target="_blank">Features</a>
      <a class="link" href="#" target="_blank">Pricing</a>
      <a class="link" href="#" target="_blank">Support</a>
      <a mat-flat-button class="register" href="#" *ngIf="showRegisterButton">REGISTER AGENCY</a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

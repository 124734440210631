/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/components/header/header.component.ngfactory";
import * as i3 from "../../core/components/header/header.component";
import * as i4 from "../../core/components/loader/loader.component.ngfactory";
import * as i5 from "../../core/components/loader/loader.component";
import * as i6 from "../../core/services/loader.service";
import * as i7 from "../../core/components/forgot-password/forgot-password.component.ngfactory";
import * as i8 from "../../core/components/forgot-password/forgot-password.component";
import * as i9 from "../../core/services/agency.service";
import * as i10 from "../../core/services/user.service";
import * as i11 from "../../core/services/auth.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/material/snack-bar";
import * as i14 from "../../core/components/footer/footer.component.ngfactory";
import * as i15 from "../../core/components/footer/footer.component";
import * as i16 from "./forgot-password.page";
var styles_ForgotPasswordPage = [i0.styles];
var RenderType_ForgotPasswordPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordPage, data: {} });
export { RenderType_ForgotPasswordPage as RenderType_ForgotPasswordPage };
export function View_ForgotPasswordPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(4, 114688, null, 0, i5.LoaderComponent, [i6.LoaderService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-forgot-password", [], null, null, null, i7.View_ForgotPasswordComponent_0, i7.RenderType_ForgotPasswordComponent)), i1.ɵdid(7, 245760, null, 0, i8.ForgotPasswordComponent, [i9.AgencyService, i10.UserService, i11.AuthService, i6.LoaderService, i12.Router, i13.MatSnackBar], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i15.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_ForgotPasswordPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-page", [], null, null, null, View_ForgotPasswordPage_0, RenderType_ForgotPasswordPage)), i1.ɵdid(1, 114688, null, 0, i16.ForgotPasswordPage, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordPageNgFactory = i1.ɵccf("app-forgot-password-page", i16.ForgotPasswordPage, View_ForgotPasswordPage_Host_0, {}, {}, []);
export { ForgotPasswordPageNgFactory as ForgotPasswordPageNgFactory };

import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/common/http";
export class AuthService {
    constructor(afAuth, http) {
        this.afAuth = afAuth;
        this.http = http;
        this.user = afAuth.authState;
    }
    /**
     *
     *
     * @param {string} username
     * @param {string} password
     * @param {boolean} rememberMe
     * @returns {Promise<firebase.auth.UserCredential>}
     * @memberof AuthService
     */
    login(username, password, rememberMe) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (rememberMe) {
                    return yield this.afAuth.auth.signInWithEmailAndPassword(username, password);
                }
                else {
                    yield this.afAuth.auth.setPersistence('session');
                    return yield this.afAuth.auth.signInWithEmailAndPassword(username, password);
                }
            }
            catch (err) {
                throw err;
            }
        });
    }
    /**
     *
     *
     * @returns {Observable<firebase.User>}
     * @memberof AuthService
     */
    isLoggedIn() {
        return this.user;
    }
    /**
     *
     *
     * @returns {Promise<void>}
     * @memberof AuthService
     */
    logout() {
        return this.afAuth.auth.signOut();
    }
    /**
     *
     *
     * @param {string} email
     * @returns {Promise<any>}
     * @memberof AuthService
     */
    resetPassword(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.apiBaseURL}/auth/password-reset?email=${email}`;
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
            return this.http.get(url, options).toPromise();
        });
    }
    /**
     *
     *
     * @param {string} email
     * @param {string} code
     * @param {string} newPassword
     * @returns {Promise<any>}
     * @memberof AuthService
     */
    changePassword(email, code, newPassword) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.apiBaseURL}/auth/confirm?type=password-reset&email=${email}`;
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
            try {
                yield this.afAuth.auth.confirmPasswordReset(code, newPassword);
                return this.http.get(url, options).toPromise();
            }
            catch (err) {
                throw err;
            }
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });

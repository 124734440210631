import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Agency } from 'src/app/models/agency';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  agency$: Observable<Agency | null>;
  agencyCollection: AngularFirestoreCollection<Agency>;

  constructor(
    private afs: AngularFirestore
  ) {
    this.agencyCollection = this.afs.collection('agency');
    const agency: Agency = JSON.parse(localStorage.getItem('agency'));
    this.agency$ =  new Observable((observer) => {
      if (agency && agency.workspaceURI) {
        observer.next(agency);
      } else {
        observer.next(null);
      }
    });
  }

  private operator(operator: string): any {
    if (operator === 'equalTo') {
      return '==';
    }
  }

  getCurrentAgency(): Observable<Agency> {
    return this.agency$;
  }

  /**
   * Get the agency details by workspace URI.
   *
   * @param {string} workspaceURI
   * @returns {Promise<Agency>}
   * @memberof AgencyService
   */
  async getByWorkspaceURI(workspaceURI: string): Promise<Agency> {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await this.afs.collection('agency',
          ref => ref.where('workspaceURI', '==', workspaceURI)).get().toPromise();
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc: any) => {
            // Add local storage data
            const agency = {
              uid: doc.id,
              ...doc.data()
            };
            resolve(agency);
          });
        } else {
          reject({
            code: 'AGENCY_DOES_NOT_EXIST',
            message: `The workspace entered does not exist, if you cannot remember your workspace`
          });
        }
      } catch (err) {
        reject({
          code: err.statusCode,
          message: err.message
        });
      }
    });
  }

  query(data: any) {
    return this.afs.collection('agency', ref =>
      ref.where(data.field, this.operator(data.operator), data.value)
    ).get().toPromise();
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private afs: AngularFirestore
  ) { }

  private operator(operator: string): any {
    if (operator === 'equalTo') {
      return '==';
    }
  }

  query(data: any) {
    return this.afs.collection('config', ref =>
      ref.where(data.field, this.operator(data.operator), data.value)
    ).get().toPromise();
  }

  getConfig() {
    return this.afs.collection('config').doc(environment.id).get().toPromise();
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { Agency } from 'src/app/models/agency';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {

  agency: Agency;
  loading = true;

  constructor(
    private router: Router,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.agency = JSON.parse(localStorage.getItem('agency'));
    // If already logged in
    this.authService.isLoggedIn().subscribe(user => {
      if (user) {
        this.router.navigate([this.agency.workspaceURI]);
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    });
  }

}

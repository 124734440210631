import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
        this.agency = JSON.parse(localStorage.getItem('agency'));
    }
    canActivate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.authService.isLoggedIn().subscribe(user => {
                    if (user) {
                        resolve(true);
                    }
                    else {
                        this.router.navigate([this.agency.workspaceURI, 'login']);
                        resolve(false);
                    }
                });
            });
        });
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: Observable<any | null>;
  userCollection: AngularFirestoreCollection<any>;

  constructor(
    private afs: AngularFirestore
  ) {
    this.userCollection = this.afs.collection('users');
  }

  private operator(operator: string): any {
    if (operator === 'equalTo') {
      return '==';
    }
  }

  query(data: any) {
    return this.afs.collection('users', ref =>
      ref.where(data.field, this.operator(data.operator), data.value)
    ).get().toPromise();
  }

  async getUserByEmail(email: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await this.afs.collection('users', ref =>
          ref.where('email', '==', email)
        ).get().toPromise();
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc: any) => {
            const user: User = {
              uid: doc.id,
              ...doc.data()
            };
            resolve(user);
          });
        } else {
          reject({
            code: 'USER_DOES_NOT_EXIST',
            message: 'User does\'t exist on the platform'
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material/material.module';
import {
  LoginComponent,
  WorkspaceComponent,
  HeaderComponent,
  FooterComponent,
  ForgotPasswordComponent,
  RegisterComponent,
  LoaderComponent,
  LogoComponent,
  UpdatePasswordComponent
} from './components';

import {
  LoaderService,
  LoaderInterceptor,
  AuthService,
  AgencyService,
  ConfigService
} from './services';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
  declarations: [
    LoginComponent,
    WorkspaceComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    LoaderComponent,
    LogoComponent,
    UpdatePasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule
  ],
  exports: [
    RouterModule,
    LoginComponent,
    WorkspaceComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    LoaderComponent,
    LogoComponent,
    UpdatePasswordComponent
  ],
  providers: [
    LoaderService, {
      provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true
    },
    ConfigService,
    AuthService,
    AgencyService,
    AuthGuard
  ]
})
export class CoreModule { }

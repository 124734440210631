import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: Observable<firebase.User>;

  constructor(
    public afAuth: AngularFireAuth,
    private http: HttpClient,
  ) {
    this.user = afAuth.authState;
  }

  /**
   *
   *
   * @param {string} username
   * @param {string} password
   * @param {boolean} rememberMe
   * @returns {Promise<firebase.auth.UserCredential>}
   * @memberof AuthService
   */
  async login(username: string, password: string, rememberMe: boolean): Promise<firebase.auth.UserCredential> {
    try {
      if (rememberMe) {
        return await this.afAuth.auth.signInWithEmailAndPassword(username, password);
      } else {
        await this.afAuth.auth.setPersistence('session');
        return await this.afAuth.auth.signInWithEmailAndPassword(username, password);
      }
    } catch (err) {
      throw err;
    }
  }

  /**
   *
   *
   * @returns {Observable<firebase.User>}
   * @memberof AuthService
   */
  isLoggedIn(): Observable<firebase.User> {
    return this.user;
  }

  /**
   *
   *
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  logout(): Promise<void> {
    return this.afAuth.auth.signOut();
  }

  /**
   *
   *
   * @param {string} email
   * @returns {Promise<any>}
   * @memberof AuthService
   */
  async resetPassword(email: string): Promise<any> {
    const url = `${environment.apiBaseURL}/auth/password-reset?email=${email}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<string>(url, options).toPromise<any>();
  }

  /**
   *
   *
   * @param {string} email
   * @param {string} code
   * @param {string} newPassword
   * @returns {Promise<any>}
   * @memberof AuthService
   */
  async changePassword(email: string, code: string, newPassword: string): Promise<any> {
    const url = `${environment.apiBaseURL}/auth/confirm?type=password-reset&email=${email}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      await this.afAuth.auth.confirmPasswordReset(code, newPassword);
      return this.http.get<string>(url, options).toPromise<any>();
    } catch (err) {
      throw err;
    }
  }

}

<mat-toolbar class="footer">
  <mat-toolbar-row>
    <div class="column">
      <div class="heading">PROPER.LY</div>
      <div class="links">
        <a class="link" href="#" target="_blank">About</a>
        <a class="link" href="#" target="_blank">Pricing</a>
        <a class="link" href="#" target="_blank">Join Us</a>
        <a class="link" href="#" target="_blank">Contact</a>
        <a class="link" href="#" target="_blank">Support</a>
      </div>
    </div>
    <div class="column">
      <div class="heading">PRODUCTS</div>
      <div class="links">
        <a class="link" href="#" target="_blank">Portal</a>
        <a class="link" href="#" target="_blank">Agent</a>
      </div>
    </div>
    <div class="column">
      <div class="heading">LEGAL</div>
      <div class="links">
        <a class="link" href="#" target="_blank">Privacy</a>
        <a class="link" href="#" target="_blank">Security</a>
        <a class="link" href="#" target="_blank">Terms of Service</a>
        <a class="link" href="#" target="_blank">Policies</a>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

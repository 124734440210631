<div class="content">
  <app-header></app-header>
  <app-loader></app-loader>
  <div class="page">
    <div *ngIf="mode === 'resetPassword'">
      <app-update-password
        [email]="email"
        [oobCode]="oobCode"
        [apiKey]="apiKey"
        [lang]="lang">
      </app-update-password>
    </div>
  </div>
</div>
<app-footer></app-footer>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/components/header/header.component.ngfactory";
import * as i3 from "../../core/components/header/header.component";
import * as i4 from "../../core/components/loader/loader.component.ngfactory";
import * as i5 from "../../core/components/loader/loader.component";
import * as i6 from "../../core/services/loader.service";
import * as i7 from "../../core/components/workspace/workspace.component.ngfactory";
import * as i8 from "../../core/components/workspace/workspace.component";
import * as i9 from "@angular/router";
import * as i10 from "../../core/services/agency.service";
import * as i11 from "@angular/material/snack-bar";
import * as i12 from "../../core/components/footer/footer.component.ngfactory";
import * as i13 from "../../core/components/footer/footer.component";
import * as i14 from "./landing.page";
var styles_LandingPage = [i0.styles];
var RenderType_LandingPage = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingPage, data: {} });
export { RenderType_LandingPage as RenderType_LandingPage };
export function View_LandingPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(4, 114688, null, 0, i5.LoaderComponent, [i6.LoaderService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-workspace", [], null, null, null, i7.View_WorkspaceComponent_0, i7.RenderType_WorkspaceComponent)), i1.ɵdid(7, 114688, null, 0, i8.WorkspaceComponent, [i9.Router, i10.AgencyService, i6.LoaderService, i11.MatSnackBar], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i13.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_LandingPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingPage_0, RenderType_LandingPage)), i1.ɵdid(1, 114688, null, 0, i14.LandingPage, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingPageNgFactory = i1.ɵccf("app-landing", i14.LandingPage, View_LandingPage_Host_0, {}, {}, []);
export { LandingPageNgFactory as LandingPageNgFactory };

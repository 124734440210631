/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/components/header/header.component.ngfactory";
import * as i3 from "../../core/components/header/header.component";
import * as i4 from "../../core/components/loader/loader.component.ngfactory";
import * as i5 from "../../core/components/loader/loader.component";
import * as i6 from "../../core/services/loader.service";
import * as i7 from "../../core/components/login/login.component.ngfactory";
import * as i8 from "../../core/components/login/login.component";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/router";
import * as i11 from "../../core/services/user.service";
import * as i12 from "../../core/services/auth.service";
import * as i13 from "@angular/material/snack-bar";
import * as i14 from "../../core/components/footer/footer.component.ngfactory";
import * as i15 from "../../core/components/footer/footer.component";
import * as i16 from "@angular/common";
import * as i17 from "./login.page";
var styles_LoginPage = [i0.styles];
var RenderType_LoginPage = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPage, data: {} });
export { RenderType_LoginPage as RenderType_LoginPage };
function View_LoginPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(4, 114688, null, 0, i5.LoaderComponent, [i6.LoaderService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-login", [], null, null, null, i7.View_LoginComponent_0, i7.RenderType_LoginComponent)), i1.ɵdid(7, 114688, null, 0, i8.LoginComponent, [i9.FormBuilder, i10.ActivatedRoute, i10.Router, i11.UserService, i12.AuthService, i6.LoaderService, i13.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
function View_LoginPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i15.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LoginPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPage_1)), i1.ɵdid(1, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPage_2)), i1.ɵdid(3, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LoginPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPage_0, RenderType_LoginPage)), i1.ɵdid(1, 114688, null, 0, i17.LoginPage, [i10.Router, i12.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageNgFactory = i1.ɵccf("app-login-page", i17.LoginPage, View_LoginPage_Host_0, {}, {}, []);
export { LoginPageNgFactory as LoginPageNgFactory };

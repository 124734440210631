import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class AgencyService {
    constructor(afs) {
        this.afs = afs;
        this.agencyCollection = this.afs.collection('agency');
        const agency = JSON.parse(localStorage.getItem('agency'));
        this.agency$ = new Observable((observer) => {
            if (agency && agency.workspaceURI) {
                observer.next(agency);
            }
            else {
                observer.next(null);
            }
        });
    }
    operator(operator) {
        if (operator === 'equalTo') {
            return '==';
        }
    }
    getCurrentAgency() {
        return this.agency$;
    }
    /**
     * Get the agency details by workspace URI.
     *
     * @param {string} workspaceURI
     * @returns {Promise<Agency>}
     * @memberof AgencyService
     */
    getByWorkspaceURI(workspaceURI) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const querySnapshot = yield this.afs.collection('agency', ref => ref.where('workspaceURI', '==', workspaceURI)).get().toPromise();
                    if (querySnapshot.docs.length > 0) {
                        querySnapshot.forEach((doc) => {
                            // Add local storage data
                            const agency = Object.assign({ uid: doc.id }, doc.data());
                            resolve(agency);
                        });
                    }
                    else {
                        reject({
                            code: 'AGENCY_DOES_NOT_EXIST',
                            message: `The workspace entered does not exist, if you cannot remember your workspace`
                        });
                    }
                }
                catch (err) {
                    reject({
                        code: err.statusCode,
                        message: err.message
                    });
                }
            }));
        });
    }
    query(data) {
        return this.afs.collection('agency', ref => ref.where(data.field, this.operator(data.operator), data.value)).get().toPromise();
    }
}
AgencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgencyService_Factory() { return new AgencyService(i0.ɵɵinject(i1.AngularFirestore)); }, token: AgencyService, providedIn: "root" });

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Validators } from '@angular/forms';
import { MustMatch } from '../../validators/auth.validator';
export class UpdatePasswordComponent {
    constructor(formBuilder, agencyService, authService, userService, loaderService, router, snackBar) {
        this.formBuilder = formBuilder;
        this.agencyService = agencyService;
        this.authService = authService;
        this.userService = userService;
        this.loaderService = loaderService;
        this.router = router;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.disabled = true;
        this.snackBarConfig = {
            panelClass: 'popup-message',
            duration: 10000,
            verticalPosition: 'top'
        };
        this.agencyService.agency$.subscribe((agency) => {
            this.agency = agency;
            this.displayURI = `${this.baseURL}${this.agency.workspaceURI}`;
        });
    }
    ngOnInit() {
        // Get the user full name
        this.userService.getUserByEmail(this.email).then(user => {
            this.fullName = `${user.firstName} ${user.lastName}`;
        });
        this.updatePasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        // Enable CTA on values matching
        this.enableCTA(this.f.password.valueChanges, this.f.confirmPassword.valueChanges);
    }
    get f() { return this.updatePasswordForm.controls; }
    /**
     * Enable the CTA button when the password and confirm
     * password values match.
     *
     * @private
     * @param {Observable<string>} password
     * @param {Observable<string>} confirmPassword
     * @memberof UpdatePasswordComponent
     */
    enableCTA(password, confirmPassword) {
        let currentPasswordValue;
        password.subscribe((value) => {
            currentPasswordValue = value;
        });
        confirmPassword.subscribe((value) => {
            if (currentPasswordValue === value) {
                this.disabled = false;
            }
            else {
                this.disabled = true;
            }
        });
    }
    /**
     * Reset the user password and sign in to the app.
     *
     * @returns {Promise<void>}
     * @memberof UpdatePasswordComponent
     */
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // stop here if form is invalid
            if (this.updatePasswordForm.invalid) {
                return;
            }
            // Disable the button
            this.disabled = true;
            // Show the loader
            this.loaderService.isLoading.next(true);
            try {
                // Update the password
                yield this.authService.changePassword(this.email, this.oobCode, this.f.password.value);
                // Login
                yield this.authService.login(this.email, this.f.password.value, true);
                const successMessage = `Welcome ${this.fullName}, your password has been updated successfully.`;
                this.snackBar.open(successMessage, 'OK', this.snackBarConfig);
                this.router.navigate([this.agency.workspaceURI]);
                this.loaderService.isLoading.next(false);
            }
            catch (err) {
                this.loaderService.isLoading.next(false);
                this.snackBar.open(err.message, 'OK', this.snackBarConfig);
            }
        });
    }
}

<mat-card class="update-password">
  <form [formGroup]="updatePasswordForm">
    <mat-card-header>
      <mat-card-title data-cy="title">
        Update Password
      </mat-card-title>
      <mat-card-subtitle data-cy="sub-title">
        <div class="domain">
          <img class="lock" src="../../../../assets/images/lock.svg" alt="lock" />
          {{ displayURI }}
        </div>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="message">
        Welcome back, <strong>{{ email }}</strong>! Please enter your
        new password below.
      </div>
      <mat-form-field class="textbox spacer" appearance="outline" floatLabel="always">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password"  data-cy="password">
        <mat-hint>Please enter your new password</mat-hint>
        <mat-error *ngIf="updatePasswordForm.get('password').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="textbox spacer" appearance="outline" floatLabel="always">
        <mat-label>Confirm Password</mat-label>
        <input formControlName="confirmPassword" matInput type="password" data-cy="confirmPassword">
        <mat-hint>Please confirm your new password</mat-hint>
        <mat-error *ngIf="updatePasswordForm.get('password').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="updatePasswordForm.get('confirmPassword').hasError('mustMatch')">
          Passwords need to match
        </mat-error>
      </mat-form-field>
      <button class="save" mat-flat-button color="primary"
        data-cy="save"
        [disabled]="disabled"
        (click)="onSubmit()">
        SAVE PASSWORD
      </button>
    </mat-card-content>
  </form>
</mat-card>

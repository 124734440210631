import { Routes } from '@angular/router';
import { RegisterComponent } from './core/components/register/register.component';
import { LandingPage, LoginPage, ForgotPasswordPage, AuthPage } from './pages';
import { AuthGuard } from './core/guards/auth.guard';
const ɵ0 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory);
const routes = [
    { path: '', component: LandingPage },
    { path: 'register', component: RegisterComponent },
    { path: ':agency', children: [
            { path: 'login', component: LoginPage },
            { path: 'forgot-password', component: ForgotPasswordPage },
            { path: 'auth', component: AuthPage },
            {
                path: '',
                loadChildren: ɵ0,
                canActivate: [AuthGuard]
            }
        ] }
];
export class AppRoutingModule {
}
export { ɵ0 };

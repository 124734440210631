import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AgencyService } from '../../services/agency.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {

  baseURL = environment.baseURL;
  agency: string;

  snackBarConfig: MatSnackBarConfig<any> = {
    duration: 10000,
    verticalPosition: 'top'
  };

  constructor(
    public router: Router,
    public agencyService: AgencyService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  checkAvailability() {
    this.agencyService.getByWorkspaceURI(`/${this.agency}`).then(querySnapshot => {
      // if (querySnapshot.docs.length > 0) {
      //   // Agency workspace URL exists already
      //   this.snackBar.open(`Workspace: '${this.agency}' already exists, please try a different one.`, 'DISMISS', this.snackBarConfig);
      // } else {
      //   // Workspace URL available, go to next section
      //   this.snackBar.open(`Workspace: '${this.agency}' is available, please choose a plan below.`, 'CHOOSE', this.snackBarConfig);
      //   this.router.navigate(['plans'], { queryParams: { workspaceURI: `/${this.agency}` }});
      // }
    }).catch(err => console.log(err));
  }

}

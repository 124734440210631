<mat-card class="forgot-password" *ngIf="!success">
  <mat-card-header>
    <mat-card-title data-cy="title">
      <button class="back-button" mat-icon-button aria-label="Back" (click)="onBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Password Reset
    </mat-card-title>
    <mat-card-subtitle data-cy="sub-title">
      <div class="sub-title">
        To reset your password, enter the email address you use to sign in to:
      </div>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="domain">
      <img class="lock" src="../../../../assets/images/lock.svg" alt="lock" />
      {{ displayURI }}
    </div>
    <mat-form-field class="textbox" appearance="outline" floatLabel="always">
      <mat-label>Email</mat-label>
      <input [(ngModel)]="email" matInput data-cy="email">
      <mat-hint>Please enter your email address</mat-hint>
    </mat-form-field>
    <button class="reset" mat-flat-button data-cy="reset" (click)="onSubmit()">
      GET RESET LINK
    </button>
  </mat-card-content>
</mat-card>
<mat-card class="forgot-password" *ngIf="success">
  <mat-card-header>
    <mat-card-title data-cy="title">
      <img class="success" src="../../../../assets/icons/success_icon.svg" alt="success" />
      Reset Code Emailed
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="domain">
      <img class="lock" src="../../../../assets/images/lock.svg" alt="lock" />
      {{ displayURI }}
    </div>
    <div class="message">
      Please check your email for instructions on how to
      reset your password.
    </div>
  </mat-card-content>
</mat-card>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AgencyService } from '../../services/agency.service';
import { MatSnackBar } from '@angular/material';
import { LoaderService } from '../../services/loader.service';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Agency } from 'src/app/models/agency';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkspaceComponent implements OnInit {

  baseURL = environment.baseURL;
  workspaceURI: string;
  disabled: boolean;

  private snackBarOptions: MatSnackBarConfig = {
    panelClass: 'data-cy-snackbar',
    duration: 10000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
  };

  constructor(
    public router: Router,
    public agencyService: AgencyService,
    public loaderService: LoaderService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    const agency: Agency = JSON.parse(localStorage.getItem('agency'));
    if (agency && agency.workspaceURI && agency.workspaceURI !== '') {
      this.router.navigate([agency.workspaceURI, 'login']);
    }
  }

  async goToLoginPage() {
    this.disabled = true;
    this.loaderService.isLoading.next(true);
    try {
      const agency = await this.agencyService.getByWorkspaceURI(this.workspaceURI);
      localStorage.setItem('agency', JSON.stringify(agency));
      this.disabled = false;
      this.loaderService.isLoading.next(false);
      this.router.navigate([this.workspaceURI, 'login']);
    } catch (err) {
      if (err.code === 'AGENCY_DOES_NOT_EXIST') {
        this.snackBar.open(err.message, 'click here', this.snackBarOptions);
      }
      if (err.code !== 'AGENCY_DOES_NOT_EXIST') {
        this.snackBar.open(err.message, '', this.snackBarOptions);
      }
      this.disabled = false;
      this.loaderService.isLoading.next(false);
    }
  }

}

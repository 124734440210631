<mat-card class="login">
  <form [formGroup]="loginForm">
    <mat-card-header>
      <mat-card-title data-cy="title">
        <button class="back-button" mat-icon-button aria-label="Back" (click)="onBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        Sign in to {{ agency?.agencyName }}
      </mat-card-title>
      <mat-card-subtitle data-cy="sub-title">
        <img class="lock" src="../../../../assets/images/lock.svg" alt="lock" />
        {{ displayURI }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="textbox" appearance="outline" floatLabel="always">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput data-cy="email">
        <mat-hint>Please enter your email address</mat-hint>
        <mat-error *ngIf="loginForm.get('email').hasError('required')">
          Email address is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="textbox spacer" appearance="outline" floatLabel="always">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" data-cy="password">
        <mat-icon matSuffix class="icon" (click)="hidePassword = !hidePassword"
          [matTooltip]="hidePassword ? 'Show password' : 'Hide password'" data-cy="show-hide">
          {{hidePassword ? 'visibility' : 'visibility_off'}}
        </mat-icon>
        <mat-hint>Please enter your password</mat-hint>
        <mat-error *ngIf="loginForm.get('password').hasError('required')">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <button class="sign-in" mat-flat-button data-cy="sign-in" (click)="onSubmit()">
        SIGN IN
      </button>
      <div class="container">
        <mat-checkbox formControlName="rememberMe" class="checkbox" color="primary">Remember me</mat-checkbox>
        <a class="link" [routerLink]="forgotPasswordLink">Forgot my password</a>
      </div>
    </mat-card-content>
  </form>
</mat-card>
<div class="join">
  Not part of this agency workspace yet? &nbsp;
  <a class="link" href="#">Request to join</a>
</div>

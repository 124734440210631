import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class UserService {
    constructor(afs) {
        this.afs = afs;
        this.userCollection = this.afs.collection('users');
    }
    operator(operator) {
        if (operator === 'equalTo') {
            return '==';
        }
    }
    query(data) {
        return this.afs.collection('users', ref => ref.where(data.field, this.operator(data.operator), data.value)).get().toPromise();
    }
    getUserByEmail(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const querySnapshot = yield this.afs.collection('users', ref => ref.where('email', '==', email)).get().toPromise();
                    if (querySnapshot.docs.length > 0) {
                        querySnapshot.forEach((doc) => {
                            const user = Object.assign({ uid: doc.id }, doc.data());
                            resolve(user);
                        });
                    }
                    else {
                        reject({
                            code: 'USER_DOES_NOT_EXIST',
                            message: 'User does\'t exist on the platform'
                        });
                    }
                }
                catch (err) {
                    reject(err);
                }
            }));
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AngularFirestore)); }, token: UserService, providedIn: "root" });

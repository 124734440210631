import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Agency } from 'src/app/models/agency';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  agency: Agency;
  showRegisterButton = true;

  constructor() { }

  ngOnInit() {
    this.agency = JSON.parse(localStorage.getItem('agency'));
    if (this.agency && this.agency.workspaceURI) {
      this.showRegisterButton = false;
    }
  }

}

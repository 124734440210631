<div class="content">
  <app-header></app-header>
  <app-loader></app-loader>
  <div class="register-wrapper">
    <mat-card>
      <mat-card-header class="header">
        <mat-card-title class="title">Create your agency workspace</mat-card-title>
        <mat-card-subtitle class="subtitle">Enter your workspace's
          <strong>Proper.ly URL</strong></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="body">
          <div class="workspace">
            <div class="domain">{{ baseURL }}</div>
            <mat-form-field class="name">
              <mat-label>workspace</mat-label>
              <input matInput [(ngModel)]="agency" placeholder="your-workspace-url" />
            </mat-form-field>
          </div>
          <button class="check" mat-raised-button (click)="checkAvailability()">
            Check Availability
          </button>
        </div>
      </mat-card-content>
      <mat-card-actions class="footer">
        <p>Already have an agency workspace URL?
          <a mat-button>Join now</a>
        </p>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<app-footer></app-footer>
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
export class WorkspaceComponent {
    constructor(router, agencyService, loaderService, snackBar) {
        this.router = router;
        this.agencyService = agencyService;
        this.loaderService = loaderService;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.snackBarOptions = {
            panelClass: 'data-cy-snackbar',
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
        };
    }
    ngOnInit() {
        const agency = JSON.parse(localStorage.getItem('agency'));
        if (agency && agency.workspaceURI && agency.workspaceURI !== '') {
            this.router.navigate([agency.workspaceURI, 'login']);
        }
    }
    goToLoginPage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.disabled = true;
            this.loaderService.isLoading.next(true);
            try {
                const agency = yield this.agencyService.getByWorkspaceURI(this.workspaceURI);
                localStorage.setItem('agency', JSON.stringify(agency));
                this.disabled = false;
                this.loaderService.isLoading.next(false);
                this.router.navigate([this.workspaceURI, 'login']);
            }
            catch (err) {
                if (err.code === 'AGENCY_DOES_NOT_EXIST') {
                    this.snackBar.open(err.message, 'click here', this.snackBarOptions);
                }
                if (err.code !== 'AGENCY_DOES_NOT_EXIST') {
                    this.snackBar.open(err.message, '', this.snackBarOptions);
                }
                this.disabled = false;
                this.loaderService.isLoading.next(false);
            }
        });
    }
}

import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
export class ForgotPasswordComponent {
    constructor(agencyService, userService, authService, loaderService, router, snackBar) {
        this.agencyService = agencyService;
        this.userService = userService;
        this.authService = authService;
        this.loaderService = loaderService;
        this.router = router;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.success = false;
        this.snackBarConfig = {
            panelClass: 'popup-message',
            duration: 10000,
            verticalPosition: 'top'
        };
        this.messages = [
            { message: 'Oops!! You do not seem to be part of this agency.', action: 'Request to join' },
        ];
    }
    ngOnInit() {
        this.agencySubscription = this.agencyService.getCurrentAgency().subscribe((agency) => {
            this.agency = agency;
            this.displayURI = `${this.baseURL}${this.agency.workspaceURI}`;
        });
    }
    ngOnDestroy() {
        this.agencySubscription.unsubscribe();
    }
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Show the loader
            this.loaderService.isLoading.next(true);
            try {
                const user = yield this.userService.getUserByEmail(this.email);
                if (user.agencyId === this.agency.uid) {
                    yield this.authService.resetPassword(this.email);
                    // Redirect to the success page
                    this.success = true;
                }
                else {
                    this.loaderService.isLoading.next(false);
                    this.snackBar.open(this.messages[0].message, this.messages[0].action, this.snackBarConfig);
                }
            }
            catch (err) {
                this.loaderService.isLoading.next(false);
                this.snackBar.open(err.message, 'OK', this.snackBarConfig);
            }
        });
    }
    onBack() {
        this.router.navigate([`/${this.agency.workspaceURI}`, 'login']);
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './core/components/register/register.component';

import {
  LandingPage,
  LoginPage,
  ForgotPasswordPage,
  AuthPage
} from './pages';

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', component: LandingPage },
  { path: 'register', component: RegisterComponent },
  { path: ':agency', children: [
    { path: 'login', component: LoginPage },
    { path: 'forgot-password', component: ForgotPasswordPage },
    { path: 'auth', component: AuthPage },
    {
      path: '',
      loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      canActivate: [ AuthGuard ]
    }
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

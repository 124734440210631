<mat-card class="workspace">
  <mat-card-header>
    <mat-card-title>Sign in to your workspace</mat-card-title>
    <mat-card-subtitle>Enter your workspace's <strong>proper.ly URL</strong></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="url">
      <img class="lock" src="../../../../assets/images/lock.svg" alt="lock" />
      <div class="domain">{{ baseURL }}</div>
      <mat-form-field class="name">
        <mat-label>workspace</mat-label>
        <input matInput [(ngModel)]="workspaceURI" placeholder="your-workspace-url" data-cy="workspace"/>
      </mat-form-field>
    </div>
    <button class="continue"
      mat-flat-button color="primary"
      data-cy="continue"
      [disabled]="disabled"
      (click)="goToLoginPage()">
      CONTINUE
    </button>
    <div class="find">
      Don't know your workspace URL?
      <a class="link" href="#">Find your workspace</a>
    </div>
  </mat-card-content>
</mat-card>
<div class="create">
  Don't have a proper.ly workspace yet? &nbsp;
  <a class="link" href="#">Register your agency</a>
</div>

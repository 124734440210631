import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Agency } from 'src/app/models/agency';
import { AgencyService, UserService, LoaderService, AuthService } from '../../services';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { Observable, Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  agency: Agency;
  agencySubscription: Subscription;
  displayURI: string;
  baseURL = environment.baseURL;
  email: string;
  success = false;

  snackBarConfig: MatSnackBarConfig<any> = {
    panelClass: 'popup-message',
    duration: 10000,
    verticalPosition: 'top'
  };

  private messages = [
    { message: 'Oops!! You do not seem to be part of this agency.', action: 'Request to join' },
  ];

  constructor(
    public agencyService: AgencyService,
    public userService: UserService,
    public authService: AuthService,
    public loaderService: LoaderService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.agencySubscription = this.agencyService.getCurrentAgency().subscribe((agency) => {
      this.agency = agency;
      this.displayURI = `${this.baseURL}${this.agency.workspaceURI}`;
    });
  }

  ngOnDestroy() {
    this.agencySubscription.unsubscribe();
  }

  async onSubmit() {
    // Show the loader
    this.loaderService.isLoading.next(true);
    try {
      const user = await this.userService.getUserByEmail(this.email);
      if (user.agencyId === this.agency.uid) {
        await this.authService.resetPassword(this.email);
        // Redirect to the success page
        this.success = true;
      } else {
        this.loaderService.isLoading.next(false);
        this.snackBar.open(this.messages[0].message, this.messages[0].action, this.snackBarConfig);
      }
    } catch (err) {
      this.loaderService.isLoading.next(false);
      this.snackBar.open(err.message, 'OK', this.snackBarConfig);
    }
  }

  onBack() {
    this.router.navigate([`/${this.agency.workspaceURI}`, 'login']);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Agency } from 'src/app/models/agency';
import { environment } from 'src/environments/environment';
import { AgencyService, AuthService, LoaderService, UserService } from '../../services';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../validators/auth.validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  @Input() email: string;
  @Input() oobCode: string;
  @Input() apiKey: string;
  @Input() lang: string;

  updatePasswordForm: FormGroup;
  agency: Agency;
  displayURI: string;
  baseURL = environment.baseURL;
  disabled = true;
  fullName: string;

  snackBarConfig: MatSnackBarConfig<any> = {
    panelClass: 'popup-message',
    duration: 10000,
    verticalPosition: 'top'
  };

  constructor(
    private formBuilder: FormBuilder,
    public agencyService: AgencyService,
    public authService: AuthService,
    public userService: UserService,
    public loaderService: LoaderService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.agencyService.agency$.subscribe((agency) => {
      this.agency = agency;
      this.displayURI = `${this.baseURL}${this.agency.workspaceURI}`;
    });
  }

  ngOnInit() {
    // Get the user full name
    this.userService.getUserByEmail(this.email).then(user => {
      this.fullName = `${user.firstName} ${user.lastName}`;
    });
    this.updatePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    // Enable CTA on values matching
    this.enableCTA(this.f.password.valueChanges, this.f.confirmPassword.valueChanges);
  }

  get f() { return this.updatePasswordForm.controls; }

  /**
   * Enable the CTA button when the password and confirm
   * password values match.
   *
   * @private
   * @param {Observable<string>} password
   * @param {Observable<string>} confirmPassword
   * @memberof UpdatePasswordComponent
   */
  private enableCTA(password: Observable<string>, confirmPassword: Observable<string>) {
    let currentPasswordValue: string;
    password.subscribe((value) => {
      currentPasswordValue = value;
    });
    confirmPassword.subscribe((value) => {
      if (currentPasswordValue === value) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    });
  }

  /**
   * Reset the user password and sign in to the app.
   *
   * @returns {Promise<void>}
   * @memberof UpdatePasswordComponent
   */
  async onSubmit(): Promise<void> {
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }

    // Disable the button
    this.disabled = true;

    // Show the loader
    this.loaderService.isLoading.next(true);

    try {
      // Update the password
      await this.authService.changePassword(this.email, this.oobCode, this.f.password.value);
      // Login
      await this.authService.login(this.email, this.f.password.value, true);
      const successMessage = `Welcome ${this.fullName}, your password has been updated successfully.`;
      this.snackBar.open(successMessage, 'OK', this.snackBarConfig);
      this.router.navigate([this.agency.workspaceURI]);
      this.loaderService.isLoading.next(false);
    } catch (err) {
      this.loaderService.isLoading.next(false);
      this.snackBar.open(err.message, 'OK', this.snackBarConfig);
    }
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { LoaderService } from '../../services/loader.service';
import { Agency } from 'src/app/models/agency';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  baseURL = environment.baseURL;
  agency: Agency;
  displayURI: string;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  hidePassword = true;
  forgotPasswordLink: string;

  snackBarConfig: MatSnackBarConfig<any> = {
    panelClass: 'popup-message',
    duration: 10000,
    verticalPosition: 'top'
  };

  private messages = [
    { message: 'Oops!! You do not seem to be part of this agency.', action: 'Request to join' },
    { message: 'Please verify your email before signing in.', action: 'Resend email' },
    { message: '', action: '' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    public authService: AuthService,
    public loaderService: LoaderService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });

    this.agency = JSON.parse(localStorage.getItem('agency'));
    if (this.agency && this.agency.workspaceURI) {
      this.displayURI = `${this.baseURL}${this.agency.workspaceURI}`;
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || `/${this.agency.workspaceURI}`;
    // Forgot password router link
    this.forgotPasswordLink = `/${this.agency.workspaceURI}/forgot-password`;
  }

  get f() { return this.loginForm.controls; }

  async onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    // Show the loader
    this.loaderService.isLoading.next(true);
    // Check if this user is registered to the agency workspace
    try {
      const user = await this.userService.getUserByEmail(this.f.email.value);
      if (user.agencyId === this.agency.uid) {
        if (user.emailVerified) {
          await this.authService.login(this.f.email.value, this.f.password.value, this.f.rememberMe.value);
          this.snackBar.open(`Welcome ${user.displayName}`, 'OK', { duration: 5000 });
          this.router.navigate([this.returnUrl]);
          this.loaderService.isLoading.next(false);
        } else {
          this.loaderService.isLoading.next(false);
          this.snackBar.open(this.messages[1].message, this.messages[1].action, this.snackBarConfig);
        }
      } else {
        this.loaderService.isLoading.next(false);
        this.snackBar.open(this.messages[0].message, this.messages[0].action, this.snackBarConfig);
      }
    } catch (err) {
      this.loaderService.isLoading.next(false);
      this.snackBar.open(err.message, 'OK', this.snackBarConfig);
    }
  }

  onBack() {
    localStorage.removeItem('agency');
    this.router.navigate(['/']);
  }

}

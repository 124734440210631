import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class ConfigService {
    constructor(afs) {
        this.afs = afs;
    }
    operator(operator) {
        if (operator === 'equalTo') {
            return '==';
        }
    }
    query(data) {
        return this.afs.collection('config', ref => ref.where(data.field, this.operator(data.operator), data.value)).get().toPromise();
    }
    getConfig() {
        return this.afs.collection('config').doc(environment.id).get().toPromise();
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ConfigService, providedIn: "root" });

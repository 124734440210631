/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/components/update-password/update-password.component.ngfactory";
import * as i3 from "../../core/components/update-password/update-password.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../core/services/agency.service";
import * as i6 from "../../core/services/auth.service";
import * as i7 from "../../core/services/user.service";
import * as i8 from "../../core/services/loader.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "../../core/components/header/header.component.ngfactory";
import * as i12 from "../../core/components/header/header.component";
import * as i13 from "../../core/components/loader/loader.component.ngfactory";
import * as i14 from "../../core/components/loader/loader.component";
import * as i15 from "@angular/common";
import * as i16 from "../../core/components/footer/footer.component.ngfactory";
import * as i17 from "../../core/components/footer/footer.component";
import * as i18 from "./auth.page";
var styles_AuthPage = [i0.styles];
var RenderType_AuthPage = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthPage, data: {} });
export { RenderType_AuthPage as RenderType_AuthPage };
function View_AuthPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-update-password", [], null, null, null, i2.View_UpdatePasswordComponent_0, i2.RenderType_UpdatePasswordComponent)), i1.ɵdid(2, 114688, null, 0, i3.UpdatePasswordComponent, [i4.FormBuilder, i5.AgencyService, i6.AuthService, i7.UserService, i8.LoaderService, i9.Router, i10.MatSnackBar], { email: [0, "email"], oobCode: [1, "oobCode"], apiKey: [2, "apiKey"], lang: [3, "lang"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.email; var currVal_1 = _co.oobCode; var currVal_2 = _co.apiKey; var currVal_3 = _co.lang; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AuthPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i11.View_HeaderComponent_0, i11.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i12.HeaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-loader", [], null, null, null, i13.View_LoaderComponent_0, i13.RenderType_LoaderComponent)), i1.ɵdid(4, 114688, null, 0, i14.LoaderComponent, [i8.LoaderService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthPage_1)), i1.ɵdid(7, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i16.View_FooterComponent_0, i16.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i17.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 4, 0); var currVal_0 = (_co.mode === "resetPassword"); _ck(_v, 7, 0, currVal_0); _ck(_v, 9, 0); }, null); }
export function View_AuthPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth", [], null, null, null, View_AuthPage_0, RenderType_AuthPage)), i1.ɵdid(1, 114688, null, 0, i18.AuthPage, [i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthPageNgFactory = i1.ɵccf("app-auth", i18.AuthPage, View_AuthPage_Host_0, {}, {}, []);
export { AuthPageNgFactory as AuthPageNgFactory };

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  agency: any;

  constructor(
    public authService: AuthService,
    private router: Router
  ) {
    this.agency = JSON.parse(localStorage.getItem('agency'));
  }

  async canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.isLoggedIn().subscribe(user => {
        if (user) {
          resolve(true);
        } else {
          this.router.navigate([this.agency.workspaceURI, 'login']);
          resolve(false);
        }
      });
    });
  }
}

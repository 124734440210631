import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Validators } from '@angular/forms';
export class LoginComponent {
    constructor(formBuilder, route, router, userService, authService, loaderService, snackBar) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.authService = authService;
        this.loaderService = loaderService;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.submitted = false;
        this.hidePassword = true;
        this.snackBarConfig = {
            panelClass: 'popup-message',
            duration: 10000,
            verticalPosition: 'top'
        };
        this.messages = [
            { message: 'Oops!! You do not seem to be part of this agency.', action: 'Request to join' },
            { message: 'Please verify your email before signing in.', action: 'Resend email' },
            { message: '', action: '' },
        ];
    }
    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: false
        });
        this.agency = JSON.parse(localStorage.getItem('agency'));
        if (this.agency && this.agency.workspaceURI) {
            this.displayURI = `${this.baseURL}${this.agency.workspaceURI}`;
        }
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || `/${this.agency.workspaceURI}`;
        // Forgot password router link
        this.forgotPasswordLink = `/${this.agency.workspaceURI}/forgot-password`;
    }
    get f() { return this.loginForm.controls; }
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // stop here if form is invalid
            if (this.loginForm.invalid) {
                return;
            }
            // Show the loader
            this.loaderService.isLoading.next(true);
            // Check if this user is registered to the agency workspace
            try {
                const user = yield this.userService.getUserByEmail(this.f.email.value);
                if (user.agencyId === this.agency.uid) {
                    if (user.emailVerified) {
                        yield this.authService.login(this.f.email.value, this.f.password.value, this.f.rememberMe.value);
                        this.snackBar.open(`Welcome ${user.displayName}`, 'OK', { duration: 5000 });
                        this.router.navigate([this.returnUrl]);
                        this.loaderService.isLoading.next(false);
                    }
                    else {
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(this.messages[1].message, this.messages[1].action, this.snackBarConfig);
                    }
                }
                else {
                    this.loaderService.isLoading.next(false);
                    this.snackBar.open(this.messages[0].message, this.messages[0].action, this.snackBarConfig);
                }
            }
            catch (err) {
                this.loaderService.isLoading.next(false);
                this.snackBar.open(err.message, 'OK', this.snackBarConfig);
            }
        });
    }
    onBack() {
        localStorage.removeItem('agency');
        this.router.navigate(['/']);
    }
}

export const environment = {
  id: 'development',
  production: false,
  baseURL: 'dev-agent.proper-ly.com/',
  apiBaseURL: 'https://us-central1-proper-ly.cloudfunctions.net/api',
  firebaseConfig: {
    apiKey: 'AIzaSyCc0PXS2qZHfYOi87dNy97RzpT6hpo-s00',
    authDomain: 'proper-ly.firebaseapp.com',
    databaseURL: 'https://proper-ly.firebaseio.com',
    projectId: 'proper-ly',
    storageBucket: 'proper-ly.appspot.com',
    messagingSenderId: '38803386981',
    appId: '1:38803386981:web:2b78f59ef9a06c0d160c8c',
    measurementId: 'G-E16N0MEXPG'
  }
};

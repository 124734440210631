import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.page.html',
  styleUrls: ['./auth.page.scss']
})
export class AuthPage implements OnInit {

  mode: string;
  email: string;
  oobCode: string;
  apiKey: string;
  lang: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.mode = queryParams.get('mode');
      this.email = queryParams.get('email');
      this.oobCode = queryParams.get('oobCode');
      this.apiKey = queryParams.get('apiKey');
      this.lang = queryParams.get('lang');
    });
  }

}
